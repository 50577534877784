<template>
  <header class="tw-relative tw-z-50 tw-container tw-mx-auto">
    <nav class="tw-flex tw-items-center tw-justify-between tw-py-7" aria-label="Global">
      <div class="tw-flex">
        <NuxtImg src="/img/logo-partners/sosovalue.png" class="tw-h-5 sm:tw-h-6 md:tw-h-7 lg:tw-h-8" />
        <div class="tw-divider tw-divider-horizontal tw-mx-1 md:tw-mx-3"></div>
        <SvgoMenuHeaderLogo
          class="tw-h-5 sm:tw-h-6 md:tw-h-7 lg:tw-h-8"
          :class="{
            'tw-fill-white': isLight,
            'tw-fill-[#111224]': !isLight,
          }"
        />
      </div>
      <div class="tw-flex tw-gap-2  md:tw-gap-5 tw-items-center">
        <span @click="onTouched" class="tw-cursor-pointer tw-uppercase tw-text-xs md:tw-text-lg lg:tw-text-base hover:tw-font-bold tw-font-semibold"
         >{{ $t('CONTACT') }}</span>
        <CommonLang :is-light="isLight" />
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
  const isLight = false
  const zenDesk = useZenDesk()
  const onTouched = () => {
    zenDesk.onToggle()
  }
</script>
