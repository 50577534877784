<template>
  <CommonThirdPartyHeader v-if="isSosoValue" />
  <div class="tw-drawer" v-else>
    <input id="my-drawer" type="checkbox" class="tw-drawer-toggle" />
    <div class="tw-drawer-content">
      <header class="tw-relative tw-z-50 tw-container tw-mx-auto">
        <nav class="tw-flex tw-items-center tw-justify-between tw-py-7" aria-label="Global">
          <div class="tw-flex">
            <NuxtLinkLocale to="/">
              <SvgoMenuHeaderLogo
                class="tw-h-[30px] lg:tw-h-10"
                :class="{
                  'tw-fill-white': isLight,
                  'tw-fill-[#111224]': !isLight,
                }"
              />
            </NuxtLinkLocale>
          </div>
          <div class="tw-flex lg:tw-hidden tw-gap-2">
            <NuxtLinkLocale
              v-if="showKyc"
              class="tw-bg-primary tw-rounded-full tw-text-white tw-text-xs tw-h-7 tw-flex tw-justify-center tw-items-center tw-px-4"
              to="/kyc"
              >TRADE WITH US</NuxtLinkLocale
            >
            <label class="tw-w-[30px] tw-h-[30px] tw-bg-white/10 tw-rounded" for="my-drawer">
              <SvgoMenuMMenu
                class="tw-h-[30px] lg:tw-h-10"
                :class="{
                  'tw-fill-white tw-stroke-white': isLight,
                  'tw-fill-[#111224] tw-stroke-[#111224]': !isLight,
                }"
              />
            </label>
            <CommonLang v-if="!showKyc" :is-light="isLight" />
          </div>

          <div class="tw-hidden lg:tw-flex lg:tw-flex-1 lg:tw-justify-end tw-gap-2 tw-flex-nowrap tw-items-center">
            <CommonMenu :is-light="isLight" en-closed />
            <CommonLang :is-light="isLight" />
          </div>
        </nav>
      </header>
    </div>
    <div class="tw-drawer-side tw-z-[999] lg:tw-hidden">
      <label for="my-drawer" aria-label="close sidebar" class="tw-drawer-overlay"></label>
      <div class="tw-bg-primary tw-text-base-content tw-min-h-full tw-w-full tw-max-w-md tw-p-4">
        <div role="dialog" aria-modal="true">
          <div class="tw-fixed tw-inset-0 tw-z-10" @touchmove.prevent></div>
          <div class="tw-fixed tw-inset-y-0 tw-right-0 tw-z-[999] tw-w-full tw-overflow-y-auto tw-bg-white tw-px-5">
            <div class="tw-flex tw-items-center tw-justify-between tw-py-10">
              <NuxtLinkLocale to="/">
                <SvgoMenuHeaderLogo class="tw-h-[30px] lg:tw-h-10 tw-fill-[#111224]" />
              </NuxtLinkLocale>
              <label for="my-drawer" aria-label="close sidebar" class="tw-w-[30px] tw-h-[30px] tw-cursor-pointer">
                <SvgoMenuMMenuClose />
              </label>
            </div>
            <div class="tw-mt-6 tw-flow-root">
              <KeepAlive>
                <CommonMenu />
              </KeepAlive>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script lang="ts" setup>
  withDefaults(defineProps<{
    isLight?: boolean
    showKyc?: boolean
  }>(),{
    isLight:false,
    showKyc:false
  })
  const { isSosoValue } = useSite()
</script>
